$(document).ready(function(){
    $('.enchant-s3-slides').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: false, 
        verticalSwiping: false,
        pauseOnHover: true,
        focusOnSelect: true,
        infinite: true,
        arrows: true,
        dots: false,
        prevArrow: '<button class="enchant-s3-carrossel-prev"></button>',
        nextArrow: '<button class="enchant-s3-carrossel-next"></button>',
        responsive: [
            {
                breakpoint: 768, 
                settings: {
                    variableWidth: true,
                    centerMode: false,
                    swipe: true,
                    infinite: true,
                    swipeToSlide: true,
                    speed: 300,
                    arrows: false,
                }
            }
        ]
    });

    $('.enchant-s5-carrossel').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
        pauseOnHover: true,
        focusOnSelect: true,
        infinite: true,
        arrows: true,
        dots: false,
        prevArrow: '<button class="enchant-s3-carrossel-prev"></button>',
        nextArrow: '<button class="enchant-s3-carrossel-next"></button>',
        responsive: [
            {
                breakpoint: 768, 
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    centerMode: true,
                }
            }
        ]
    });
});