import 'jquery-validation';
import faker from 'faker-br';

import Utils from '../utils.js';
import UTM from '../utm.js';

document.addEventListener('sebDocumentReady', function() {

	var unitsLoaded = false;

	function formatDateToFetch(date) {
		const new_date = date.split('/');
		return new_date[2] + '-' + new_date[1] + '-' + new_date[0];
	}

	function canRemoveIsLoading() {

		if(unitsLoaded) {
			return true;
		}

		return false;
	}

	function setIsLoading(element) {
		element.addClass('is-loading')
	}
	function unsetIsLoading(element) {
		element.removeClass('is-loading')
	}

	function attachUnitListener(element) {

		const unitSelect = element.find('select[name="unit_schedule"]')
		unitSelect.on('selectmenuchange', function() {

			const unit_id = jQuery(this).val();
			const redirectSection = element.find('.to-show');
			element.find('.to-hide').removeClass('d-none');
			element.find('label.error').removeClass('d-none-important');
			redirectSection.hide();
		})
	}

	function loadUnits(element) {
		let selectedUnits = jQuery('.contact.schedule').data('selected-schools');
		selectedUnits = selectedUnits.replace(/\s+/g, '');
		selectedUnits = selectedUnits.replace(/\[/g, '');
		selectedUnits = selectedUnits.replace(/\]/g, '');
		selectedUnits = selectedUnits.replace(/'/g, '');

		setIsLoading(element)
		fetch(`/dynamics/maple/units`, {
			method: 'GET'
		}).then(response => response.json())
		.then(function(response) {

			const lang = jQuery('html').attr('lang').toLowerCase()

			const select = element.find('select[name="unit_schedule"]');
			select.empty().append('<option value="" disabled selected></option>');

			let  filteredResponse;

			if(selectedUnits == 'None') {
				filteredResponse = response;
			} else {
				filteredResponse = response.filter(res => {
					if (["3dd24446-f2c6-ec11-a7b6-002248d30e30", "0312937d-31f5-eb11-94ef-00224836a9a9"].includes(res.mb_escolaid)) {
						const match = res.mb_name.match(/^Escola Maple Bear \((.*?)\) (.*)$/);
						if (match) res.mb_name = `Escola Maple Bear ${match[2]} - ${match[1]}`;
					}
					return selectedUnits.includes(res.mb_escolaid);
				});
			}
			
			filteredResponse.sort((a,b) => a.mb_name.localeCompare(b.mb_name))

			for(const unit of filteredResponse) {
				const title = []
				const value = unit.mb_escolaid
				const owner = unit._ownerid_value
				title.push(unit.mb_name)
				select.append('<option value="'+value+'" data-owner="'+owner+'">'+title+'</option>');
			}


			if (UNIT_INFO.is_institutional == 'False') {
				$('select[name="unit_schedule"]').prop("selectedIndex", 1);
				select.addClass('valid');
			}

			if (UNIT_INFO.is_institutional == 'True') {
				select.select2({
					dropdownParent: $('select[name="unit_schedule"]').parent(),
					forceabove: true
				}).on('select2:select', function(e) {
					select.addClass('valid');
				})
			}

			unitsLoaded = true;
			if(canRemoveIsLoading()) {
				unsetIsLoading(element)
			}
		})
	}

	function fakeFormData(form) {
		let birthDate = faker.date.past(20, new Date(2002, 0, 1));
		let formatter = new Intl.DateTimeFormat('pt-BR');
		let birthDateFormatted = formatter.format(birthDate);

		form['first_name'].value = faker.name.firstName();
		form['last_name'].value = faker.name.lastName();
		form['email'].value = faker.internet.email();
		form['phone'].value = faker.phone.phoneNumber('(21) #####-####');
		form['name_student'].value = faker.name.firstName() + ' ' + faker.name.lastName();
		form['birth_student'].value = birthDateFormatted;
		form['add_info'].value = faker.lorem.paragraph();
	}
	
	const scheduleVisitation = jQuery('.contact .form-schedule');
	scheduleVisitation.each(function(index) {

		const current = jQuery(this);
		const form = jQuery(this).find('form');

		if (process.env.NODE_ENV === 'development') {
			fakeFormData(form[0]);
		}

		loadUnits(current)
		attachUnitListener(current)

		function handleSuccess() {


			const formBoxes = current.find('.form-box');
			const successMessage = current.find('.step-success');
			formBoxes.hide();
			successMessage.show();

			current.removeClass('is-loading');
		}

		function pushToDatalayer(status) {

			if(typeof dataLayer !== 'undefined') {

				const segment = form.find('[name="segment"]').find("option:selected").text();
				const type = form.find('[name="visitor"]:checked').val() == 'Responsável' ? 'responsavel' : 'aluno';
						
				if(UNIT_INFO.is_institutional == 'True') {

					dataLayer.push({
						'unidade-interesse': form.find('[name="unit_schedule"]').find("option:selected").text(),
						'segmento-interesse': segment,
						'tipo-usuario': type,
						'tipo-validacao': status,
						'event': 'Exibição'
					});
				
				} else {
				
					dataLayer.push({
						'unidade-interesse': UNIT_INFO.site_name,
						'segmento-interesse': segment,
						'tipo-usuario': type,
						'tipo-validacao': status,
						'event': 'Exibição'
					});
				}
			}
		}

		var validated = false;
		const dynamicsErrorMessage = current.find('.erro-dynamics')

		const validator = form.validate({
			rules: {
				unit_schedule: {
					required: true,
				},
				first_name: {
					required: true,
				},
				last_name: {
					required: true,
				},
				email: {
					required: true,
					email: true,
					minlength: 3,
					maxlength: 100
				},
				phone: {
					required: true,
					minlength: 14,
					maxlength: 15
				},
				name_student: {
					required: true,
					minlength: 3,
					maxlength: 100
				},
				birth_student: {
					required: true,
					validDate: true
				},
				add_info: {
					required: false,
				}
			},
			errorPlacement: function(error, element) {

				var type = element.attr('type');
				validated = true;

				if(element.parent().hasClass('form-control')) {

					error.insertAfter(element.parent());
					return;
				}
				if(element.get(0).tagName.toLowerCase() == 'select') {

					element.parent().append(error)
					return;
				}
				if(type == 'checkbox') {

					var parent = element.closest('.checkbox-container');
					error.css('order', parent.css('order'));
					error.insertAfter(parent);
					return;
				}

				error.insertAfter(element);
			},
			submitHandler: function() {

				const captcha = grecaptcha.getResponse();
				if(!captcha) {

					current.find('.recaptcha-feedback').show();
					return;
				
				} else {
				
					current.find('.recaptcha-feedback').hide();
				}

				dynamicsErrorMessage.hide()


				const csrfToken = Utils.getCookie('csrftoken');
				const data = {

					first_name: form.find('[name="first_name"]').val(),
					last_name: form.find('[name="last_name"]').val(),
					email: form.find('[name="email"]').val(),
					mobile_phone: Utils.apenasNumeros(form.find('[name="phone"]').val()),
					student_name: form.find('[name="name_student"]').val(),
					student_date_birth: formatDateToFetch(form.find('[name="birth_student"]').val()),
					school_interest: form.find('[name="unit_schedule"]').val(),

					owner: jQuery('select[name="unit_schedule"] option:selected').data("owner"),
					additional_information: form.find('[name="add_info"]').val(),
					origin_potential_customer: form.find('[name="origin"]').data("origin"),

					recaptcha_codigo: captcha,
				};

				const utm_params = UTM.getUTM();

				const formData = {
					...utm_params,
					...data
				}

				current.addClass('is-loading');
				fetch('/dynamics/maple/leads/', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'X-CSRFToken': csrfToken,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(formData)
				}).then(function(response) {

					if(response.ok) {
					
						const redirect_success = form.find('[name="redirect_success"]');
						if(redirect_success.length > 0) {
						
							window.location.href = redirect_success.val();
						
						} else {

							form.remove()
							handleSuccess(current);
						}

					} else if(response.status == 409) {

						const redirect_duplicate = form.find('[name="redirect_duplicate"]');
						if(redirect_duplicate.length > 0) {

							window.location.href = redirect_duplicate.val();
						
						} else {

							dynamicsErrorMessage.show()
							current.removeClass('is-loading');
						}

					} else {

						const redirect_error = form.find('[name="redirect_error"]');
						if(redirect_error.length > 0) {
		
							window.location.href = redirect_error.val();
						
						} else {

							dynamicsErrorMessage.show()
							current.removeClass('is-loading');
						}
					}

				}).catch(function(error) {

					dynamicsErrorMessage.show()
					current.removeClass('is-loading');
				})

				return false;
			},
		});
	});
});